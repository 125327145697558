import * as React from 'react';
import {faSearch, faTimesCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FilterInputContainer} from './FilterInput.styles';
import {useRef, useState} from 'react';

export type FilterInputProps = {
    className?: string;
    onInput: (value) => void;
    placeholder?: string;
};

export const FilterInput = ({className, onInput, placeholder}: FilterInputProps) => {
    const [filter, setFilter] = useState(``);
    const filterBox = useRef<HTMLInputElement>();

    /**
     * Clears filter text
     */
    const clearFilterBoxText = () => {
        filterBox.current.value = ``;
        filterBox.current.dispatchEvent(new Event(`input`, {bubbles: true}));
    };

    /**
     * Template
     */
    return (
        <FilterInputContainer>
            <div className={`input-group ${className}`}>
                <div className="input-group-prepend">
                    <span className="input-group-text bg-white">
                        <FontAwesomeIcon
                            className="gray-200"
                            icon={faSearch}
                        />
                    </span>
                </div>
                <input
                    aria-label="filter"
                    className="filter-input form-control border-left-0 border-right-0"
                    id="filterbox"
                    onInput={(e) => {
                        onInput(e.currentTarget.value);
                        setFilter(e.currentTarget.value);
                    }}
                    placeholder={placeholder || `Search`}
                    ref={filterBox}
                    type="text"
                />
                <div className="input-group-append">
                    <span className="input-group-text bg-white">
                        {filter && (
                            <FontAwesomeIcon
                                className="gray-200"
                                icon={faTimesCircle}
                                onClick={clearFilterBoxText}
                            />
                        )}
                    </span>
                </div>
            </div>
        </FilterInputContainer>
    );
};
